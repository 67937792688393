<!-- 七乐彩基本走势图 -->
<template>
    <div class="">
        <div class="ssq-table">
            <div class="ssq-table-header">
                <div class="ssq-icon">
                    <svg t="1621310782405" class="icon" viewBox="0 0 1024 1024" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" p-id="2538" width="45" height="45">
                        <path
                            d="M541.408711 157.6704h122.3936l44.347733-84.1216-199.529244-1.803378-266.146133 660.383289h63.644444z"
                            fill="#ffffff" p-id="2539"></path>
                        <path
                            d="M585.764978 271.402667h127.689955l44.828445-90.641067h-203.374934l-264.220444 666.843022h61.715911z"
                            fill="#ffffff" p-id="2540"></path>
                        <path
                            d="M813.343289 294.408533h-210.759111l-270.427022 657.846045h209.251555l90.643911-205.960534h126.2336s207.414044 9.910044 234.416356-232.490666c0 0 11.5712-160.924444-179.359289-219.394845z m1.823289 260.667734c-49.169067 80.275911-133.046044 52.315022-133.046045 52.315022l65.558756-159.647289c116.656356 6.764089 67.487289 107.335111 67.487289 107.332267zM203.901156 506.587022c40.4992-92.020622 128.253156-54.129778 128.253155-54.129778l69.415822-158.048711c0-14.819556-323.487289-52.562489-369.316977 239.246223-17.3568 176.822044 177.874489 204.694756 177.874488 204.694755l51.646578-119.893333c-96.079644-59.630933-57.873067-111.869156-57.873066-111.869156z"
                            fill="#ffffff" p-id="2541"></path>
                    </svg>
                    <div class="ssq-one">
                        <h2>中国福利彩票</h2>
                    </div>
                    <div class="ssq-two">
                        <h3>快 乐 8 胆 拖 投 注 表</h3>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
            <table class="tab" border="1" cellspacing="0" cellpadding="0" v-for="(item0 ,i) in klb" :key='i'>
                <thead class="thead-fixed">
                    <tr>
                        <th rowspan="2" colspan="2" class="ranks_a">{{item0.title}}</th>
                        <th colspan="33" class="ranks_b">拖码个数</th>
                    </tr>
                    <tr>
                        <th class="ranks_c" v-for="(item , i) in tuo" :key="i">{{item}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th rowspan="10" class="ranks_a">胆码个数</th>
                    </tr>
                    <tr v-for="(item ,i) in item0.dan" :key='i'>
                        <!-- 三区走势图 -->
                        <td >
                            <div>
                                {{item}}
                            </div>
                        </td>
                        <td class="b-lan" v-for="(item1 ,i) in tuo" :key='i' >
                            <div>
                                {{item+item1>item0.dan+1?mon(item1-(item0.dan+1-item)+1,item1,item0.dan+1-item,1):'X'}}
                            </div>
                        </td>

                    </tr>

                </tbody>
            </table>
          
        </div>
    </div>
</template>

<script>

    //import axios from 'axios'
    //import '../js/font.js' //js文件引入，注意路径
    //import {myfun} from '../js/test.js' //js文件引入，注意路径
    //import Introduction from '@/views/Introduction.vue'
    export default {
        name: 'qlc-basic',
        components: {

        },
        data() { // 数据源
            return {
                klb:[{"title":"选二","dan":1},
                {"title":"选三","dan":2},
                {"title":"选四","dan":3},
                {"title":"选五","dan":4},
                {"title":"选六","dan":5},
                {"title":"选七","dan":6},
                {"title":"选八","dan":7},
                {"title":"选九","dan":8},
                {"title":"选十","dan":9},],
                tuo: [2,3,4,5,6,7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,26,27,28,29,30],
            }
        },
        mounted() {
            this.toTop()
        },
        computed: {


        },
        methods: { // 函数体部分，js的主要逻辑控制  
            toTop() {
                document.documentElement.scrollTop = 0;
            },
            mon(min, max, wangs, langs) {
				var s = 1;
				for (var n = min - 1; n < max; n++) {
					s = s * (n + 1)
				}
				for (var n1 = wangs; n1 > 0; n1--) {
					s = s / n1
				}
				return s * langs * 2

			},
            MO(a,b){
                return  Math.abs(a-b)
            }
        },
        props: {
        },
        watch: {

        }
    }
</script>

<style scoped>
    /* @import './swiper.css'; */
    .ssq-table-header {
        width: 100%;
        height: 50px;
        background-color: red;
    }

    .tab {
        margin: 0 auto;
        border-collapse: collapse;
    }

    .thead-fixed th {
        border: 1px solid #808080;
    }

    .ranks_a {
        width: 30px;
    }

    .ranks_a1 {
        width: 25px;
    }

    .ranks_c {
        width: 60px;
        color: rgb(76, 0, 255);
    }

    .ranks_d {
        width: 22px;
        color: blue;
    }

    .ssq-icon {
        text-align: left;
    }

    .icon {
        float: left;
    }

    .ssq-one {
        float: left;
        height: 42px;
        line-height: 42px;
        color: #fff;

    }

    .ssq-two {
        float: left;
        width: 80%;
        text-align: center;
        line-height: 42px;
        font-size: 25px;
        color: rgb(252, 251, 249);
    }

    .b-red {
        height: 23px;
        background-color: rgb(215, 191, 224);
    }

    .b-lan {
        background-color: rgb(221, 220, 171);
    }

    .q-red {
        background-color: rgb(163, 45, 100);
        border-radius: 15px;
        color: #fff;
    }

    .q-lan {
        background-color: rgb(67, 67, 179);
        color: #fff;
        border-radius: 15px;
    }

    tbody .xuan {
        background-color: rgb(160, 156, 156);
        border-radius: 15px;
        color: rgb(253, 253, 253);
    }





    .no {
        display: none;
    }
</style>
<!-- 语法 -->
<template>
    <div class="">
        <fu></fu>
        <dan></dan>
    </div>
</template>

<script>
     import fu from '@/components/wanfa/klbfu.vue'
    import dan from '@/components/wanfa/klbdan.vue'
    //import '../js/font.js' //js文件引入，注意路径
    //import {myfun} from '../js/test.js' //js文件引入，注意路径
    //import Introduction from '@/views/Introduction.vue'
    //import api from '@/js/method'
    export default {
        name: '',
        components: {
            fu,
            dan
        },
        data() { // 数据源
            return {
            }
        },
        mounted() {

        },
        computed: {
          
        },
        methods: { // 函数体部分，js的主要逻辑控制               
        },
        props: {
        }
    }
</script>

<style scoped>
    /* @import './swiper.css'; */
</style>